<template>
    <div>
        <b-overlay :show="showLoading" no-wrap />
        <div class="row mb-2">
            <div class="col">
                    <div class="d-flex">
                        <div class="position-relative mr-3">
                            <img :src="logo"  alt="logo" id="logo" height="120" />
                            <b-button  v-if="edit" variant="primary"
                                size="sm"
                                @click="$refs.refInputEl.$el.click()"
                                class="btn-icon rounded-circle edit-img">
                                <feather-icon icon="EditIcon" />
                            </b-button>
                        </div>
                        <!-- <a style="margin-left:200px; width: 10px; height: 10px; cursor:default" @click="update"></a> -->
                        <div v-if="currentUser.assureur_user.isPrincipal">
                            <div>
                                <b-form-checkbox v-if="!isCatalogue" v-model="edit" name="check-button" switch inline></b-form-checkbox>
                            </div>
                        </div>
                    </div>
                
                <b-col class="md-12" v-if="edit">
                    <!-- media -->
                    <b-media no-body>
                        <b-media-body class="mt-75 ml-75">
                            <b-form-file ref="refInputEl" accept=".jpg, .png, .gif" @input="readURL" :hidden="true"
                                plain />
                            <!--/ upload button -->
                        </b-media-body>
                    </b-media>
                    <!--/ media -->
                </b-col>
            </div>
            <div class="col-auto" v-if="isCatalogue">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="outline-primary"
                @click="backStep"
              >
              <feather-icon 
               icon="ArrowLeftIcon"
               class="mr-50"
              />
                REVENIR À LA LISTE
              </b-button>
            </div>
        </div>
        <section>
            <b-row>
                <b-col lg="9">
                    <b-card>
                        <b-card-header class="flex-column align-items-start pb-1">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FeatherIcon" size="20" />DESCRIPTION</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <div v-if="!edit" v-html="assureur ? assureur.description : null" class="text-justify"></div>
                            <div v-else>
                                <b-row>
                                    <b-col class="col-12">
                                        <quill-editor v-model="assureur.description" />
                                    </b-col>
                                    <b-col class="col text-right">
                                        <b-button variant="primary" class="mt-1" @click="updateDescription()">
                                            <feather-icon icon="CheckIcon" />
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-card>
                            <b-card-header class="flex-column align-items-start pb-1">
                                    <b-card-title>
                                        <h4 class="title-custom-wizard">
                                            <feather-icon class="box-icon-text" icon="DollarSignIcon" size="20" />COMMISSIONS</h4>
                                    </b-card-title>
                                <b-card-text class="text-muted mt-25"></b-card-text>
                            </b-card-header>

                            <b-card-body>
                                <div v-if="!edit" v-html="assureur ? assureur.commission : null" class="text-justify"></div>
                                    <div v-else>
                                        <b-row>
                                            <b-col class="col-12">
                                                <quill-editor v-model="assureur.commission" />
                                            </b-col>
                                            <b-col class="col text-right">
                                                <b-button variant="primary" class="mt-1" @click="updateCommission()">
                                                    <feather-icon icon="CheckIcon" />
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </div>
                            </b-card-body>
                        
                        </b-card>

                    <b-card>

                        <b-card-header class="flex-column align-items-start pb-0">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="PackageIcon" size="20" />PRODUITS</h4>
                            </b-card-title>
                            
                        </b-card-header>
                        <b-card-body>
                            <div class="demo-inline-spacing">
                                <b-button  size="lg" variant="outline-primary" v-for="produit in produits" :key="produit.value" @click="changeProduit(produit.value)">
                                    {{ produit.risque }} - {{ produit.text }}
                                </b-button>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col lg="3">
                    <interlocuteurs @fetchInterlocuteur="fetchInterlocuteur" :interlocuteurs = "interlocuteurs" :assureur_id ="userData.assureur_user.assureur_id" :edit=edit :is-boite-sync="isBoiteSync"></interlocuteurs>
                </b-col>
            </b-row>
        </section>
    </div>

</template>
<script>
import {
    mapGetters
} from "vuex";

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
    heightTransition
} from '@core/mixins/ui/transition'
import {
    quillEditor
} from 'vue-quill-editor'
import BCardCode from '@core/components/b-card-code'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'

import interlocuteurs from './interlocuteurs/Interlocuteurs.vue'

import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import {
    BImg,
    BOverlay,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BCard,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
    BAvatar,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BFormCheckboxGroup,
    BFormTextarea
} from 'bootstrap-vue'
import {
    required, email
} from '@validations'
export default {
    components: {
        Ripple,
        BForm,
        BFormGroup,
        BFormInput,
        BImg,
        BOverlay,
        BRow,
        BCol,
        BModal,
        BAvatar,
        BFormFile,
        BCardHeader,
        BCardTitle,
        vSelect,
        BFormSelect,
        BFormSelectOption,
        BCardCode,
        quillEditor,
        ValidationProvider,
        ValidationObserver,
        // ToastificationContent,
        BFormCheckbox,
        BListGroup,
        BListGroupItem,
        BCardText,
        BLink,
        BCard,
        BCardBody,
        BTabs,
        BTab,
        BButton,
        BFormTextarea,
        BMedia,
        BMediaAside,
        BFormCheckboxGroup,
        BMediaBody,
        interlocuteurs
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    mixins: [heightTransition],
    data() {
        return {
            currentUser: JSON.parse(localStorage.getItem("userData")),
            isBoiteSync: false,
            showLoading:false,
            produitType:null,
            assureur: null,
            userData: null,
            interlocuteurs: [],
            produits: [],
            logo: null,
            edit: false,
            base64regex: /^data:image\/([a-zA-Z]*);base64,([^\"]*)?$/,
            required,
            email
        }
    },
    props: {
        isCatalogue: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    computed: {
        ...mapGetters({services : "getServices", objectAssurance : "getObjet"}),
    },
    created: function () {
        this.userData = JSON.parse(localStorage.getItem('userData'))
        this.checkSyncBoiteEmail()
        this.getAssureur()
        this.fetchInterlocuteur(this.userData.assureur_user.assureur_id)
        // this.getAllProduits()
        // this.$store.dispatch('getAllServices')
    },
    methods: {
        update() {
            if (this.edit) {
                this.edit = false
            } 
            else {
                this.edit = true
            }
            
        },
        fetchInterlocuteur(id) {
            this.showLoading = true
            this.$http
                .get(`/portefeuilles/getInterlocuteur/${id}`)
                .then((res) => {
                    this.interlocuteurs = res.data.data
                    console.log(res.data.data)
                    this.showLoading = false
                })
                .catch((err) => {
                    this.showLoading = false
                    console.log(err);
                });

        },
        checkSyncBoiteEmail() {
            this.isBoitEmailSync().then(response => {
                if(response)
                this.isBoiteSync = true
                else this.isBoiteSync = false
            })
        },
        backStep(){
            this.showLoading = true
            this.$store.commit("setFromList",false);
            this.$store.commit("setStep", {
                step: 'searchCatalogue',
            });
            this.showLoading = false
        },
        // getAllProduits() {
        //     let assureur_id = this.objectAssurance.data !== undefined ? this.objectAssurance.data.assureurId : null
        //     this.$http.get(assureur_id ? `produit/getAllProduits/${assureur_id}` : `produit/getAllProduits`)
        //         .then((res) => {
        //             this.produits = []
        //             Array.from(res.data).forEach(produit =>
        //                 this.produits.push({
        //                     text: produit.produit_nom,
        //                     value: produit.id
        //                 })
        //             );
        //         })
        //         .catch((e) => {
        //             console.log(e);
        //         })
        // },
        getAssureur() {
            let assureur_id = this.userData.assureur_user.assureur_id ? this.userData.assureur_user.assureur_id : this.objectAssurance.data.assureurId
            this.showLoading = true
            this.$http
                .get(`assureurs/getAssureurDetailByID/${assureur_id}`)
                .then((response) => {
                    this.assureur = response.data
                   
                    if(this.base64regex.test(this.assureur.assureur_image))
                        this.logo = this.assureur.assureur_image
                    
                    if(response.data.produits) {
                        this.produits = []
                        Array.from(response.data.produits).forEach(produit =>
                            this.produits.push({
                                text: produit.produit_nom,
                                value: produit.id,
                                risque: produit.risque.label,

                            })
                        );
                    }
      

                    this.showLoading = false
                }).catch((err) => {
                    console.log(err)
                    this.showLoading = false
                });
        },
        updateDescription() {
            this.showLoading = true

            let assureur_id = this.userData.assureur_user.assureur_id ? this.userData.assureur_user.assureur_id : this.objectAssurance.data.assureurId
            let data = {
                id: assureur_id,
                description: this.assureur.description
            }
            this.$http
                .post(`assureurs/updateDescription`, data)
                .then((response) => {
                    if (response.data.success) {
                        this.messageToast(response.data.message, 'Assureur', 'success', 'BellIcon')                        
                        this.showLoading = false
                    }
                }).catch((err) => {
                    this.showLoading = false
                    console.log(err)
                });


        },
        updateCommission() {
            this.showLoading = true
            let assureur_id = this.userData.assureur_user.assureur_id ? this.userData.assureur_user.assureur_id : this.objectAssurance.data.assureurId
            let data = {
                id: assureur_id,
                commission: this.assureur.commission
            }
            this.$http
                .post(`assureurs/updateCommission`, data)
                .then((response) => {
                    if (response.data.success) {
                        this.messageToast(response.data.message, 'Assureur', 'success', 'BellIcon')                        
                        this.showLoading = false
                    }
                }).catch((err) => {
                    this.showLoading = false
                    console.log(err)
                });
        },
        readURL() {
            this.showLoading = true
            let assureur_id = this.userData.assureur_user.assureur_id ? this.userData.assureur_user.assureur_id : this.objectAssurance.data.assureurId
            let input = this.$refs.refInputEl
            let file = input.files

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            let formData = new FormData()
            formData.append('file', file[0])
            formData.append('assureur', assureur_id)
            

            this.$http.post(`assureurs/updateAssureurImage`, formData, config).then(r => {
                if (r) {
                    if (file && file[0]) {
                        let reader = new FileReader
                        reader.onload = e => {
                            this.logo = e.target.result
                        }
                        reader.readAsDataURL(file[0])
                        this.$emit('input', file[0])
                    }
                    this.messageToast(r.data.message, 'Assureur logo', 'success', 'BellIcon')                    
                    this.showLoading = false
                }
            }).catch(err => {
                
                this.showLoading = false
            })
        },
        changeProduit(produit_id) {
            this.showLoading = true
            let data = {
                type: 'produit',
                data: null
            }
            this.$http
                .get(`produit/getDetailsProduit/${produit_id}`)
                .then((r) => {
                    data.data = r.data
                    this.$store.commit("setProduit", {
                        produit: data,
                    });
                    this.$store.commit("setProd", {
                        prod: 'ProduitDetail',
                    });
                    
                    if(!this.isCatalogue) {
                        this.$router.push({ name: 'produits-list', params: { back_to: 'assureur-profile' }})
                    } else {
                        this.$store.commit("setFromList",false);
                        this.$store.commit("setStep", {
                            step: 'ProduitDetail',
                        });
                    }
                        
                    this.showLoading = false
                }).catch((err) => {
                    console.log(err)
                    this.showLoading = false
                });
        }
    }
}
</script>
<style>
.title-custom-wizard{
    color: #4d25bc !important ;
    margin-bottom: 5px;
}

.box-icon-text{
    margin-top: -4px;
    margin-right: 5px;
}
.edit-img {
    position: absolute;
    bottom: -5px;
    right: -15px;
    z-index: 6;
}
</style>