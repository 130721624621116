<template>
    <div>
    <b-card>
        <b-card-header class="d-flex justify-content-start align-items-center pb-1">
            <b-card-title>
                <h4 class="title-custom-wizard">
                    <feather-icon class="box-icon-text" icon="UsersIcon" size="20" />INTERLOCUTEURS</h4>
            </b-card-title>
            <b-button @click="showModal()" variant="primary" class="btn-icon ml-auto" size="sm" v-if="edit" v-b-modal.modal-interlocuteur>
                <feather-icon icon="UserPlusIcon" />
            </b-button>
        </b-card-header>
        <b-card-body>
            <!-- user suggestion  -->
            <div v-for="(interlocuteur) in interlocuteurs" :key="interlocuteur.id"
                class="mb-2">
                <b-avatar :src="interlocuteur.image" class="mr-50 mb-1" size="40" />
                <div class="user-page-info">
                    <h6 class="mb-0">
                        {{ interlocuteur.fonction }}
                    </h6>
                    <small v-if="interlocuteur.prenom != '' && interlocuteur.nom != '' && interlocuteur.prenom != null && interlocuteur.nom != null" class="text-muted d-block">{{ interlocuteur.prenom }} {{ interlocuteur.nom }}</small>
                    <small class="text-muted d-block">{{ interlocuteur.phone }}</small>
                    <small class="text-muted d-block">
                        <a :style="isBoiteSync ? { 'text-decoration': 'underline' } : { 'text-decoration': 'initial', 'cursor': 'default' }" @click="showEmail(interlocuteur.email)">
                            {{ interlocuteur.email }}
                        </a>
                    </small>
                </div>
                <b-button @click="showModal(interlocuteur)"  variant="primary" class="btn-icon ml-auto" size="sm" v-if="edit"
                    v-b-modal.modal-interlocuteur>
                    <feather-icon icon="EditIcon" />
                </b-button>
            </div>
            <!--/ user suggestion  -->
        </b-card-body>
    </b-card>
    <b-modal id="modal-interlocuteur"   :title="`${interlocuteur.id != null ? 'Update' : ' Ajouter'} document`" ref="modal-interlocuteur" ok-title="Enregistrer"
            cancel-title="Exit" cancel-variant="outline-secondary" @hidden="resetModal"
            @ok="handleOk">
            <validation-observer ref="interlocuteurRules">
                <b-form ref="interlocuteurForm" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Fonction" label-for="fonction">
                        <validation-provider #default="{ errors }" name="fonction" rules="required" >
                            <b-form-input :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Fonction" id="fonction" v-model="interlocuteur.fonction" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Nom" label-for="nom">
                        <validation-provider #default="{ errors }" name="nom" >
                            <b-form-input :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Nom" id="nom" v-model="interlocuteur.nom" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Prenom" label-for="prenom">
                        <validation-provider #default="{ errors }" name="prenom">
                            <b-form-input :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Prenom" id="prenom" v-model="interlocuteur.prenom" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Téléphone" label-for="phone">
                        <validation-provider #default="{ errors }" name="Téléphone" rules="required" >
                            <b-form-input :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Téléphone" id="phone" v-model="interlocuteur.phone" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label="Email" label-for="email">
                        <validation-provider #default="{ errors }" name="email" rules="required|email" >
                            <b-form-input :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Email" id="email" type="email" v-model="interlocuteur.email" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>
<script>
import {
    mapGetters
} from "vuex";
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import {
    required, email
} from '@validations'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    BImg,
    BOverlay,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BCard,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
    BAvatar,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BFormCheckboxGroup,
    BFormTextarea
} from 'bootstrap-vue'
export default {
    name: 'Interlocuteurs',
    props: ['edit', 'interlocuteurs', 'is-boite-sync','assureur_id'],
    components: {
        BForm,
        BFormGroup,
        BFormInput,
        BImg,
        BOverlay,
        BRow,
        BCol,
        BModal,
        BAvatar,
        BFormFile,
        BCardHeader,
        BCardTitle,
        vSelect,
        BFormSelect,
        BFormSelectOption,
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        // ToastificationContent,
        BFormCheckbox,
        BListGroup,
        BListGroupItem,
        BCardText,
        BLink,
        BCard,
        BCardBody,
        BTabs,
        BTab,
        BButton,
        BFormTextarea,
        BMedia,
        BMediaAside,
        BFormCheckboxGroup,
        BMediaBody,
    },
    directives: {
        'b-modal': VBModal,
    },
    data() {
        return {
            services: [],
            interlocuteur: {
                id: null,
                fonction: null,
                nom: null,
                prenom: null,
                phone: null,
                email: null,
                image: require('@/assets/images/avatars/12-small.png'),
                assureur_id: null
            },
            required,
            email
        }
    },
    mounted() {

    },
    methods: {
        showEmail (email, copierCarbon = null) {
            if(this.isBoiteSync) {
                this.$router.push({
                    name: 'messageries',
                    params: {
                    operation: 'send',
                    email,
                    copierCarbon
                    }
                })
            }
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit(event) {
            
            this.$refs.interlocuteurRules.validate().then(success => {
                if (success) {
                    this.$http.post(`portefeuilles/addOrUpdateInterlocuteur`, this.interlocuteur).then(r => {
                        this.hideModal()
                        this.$emit('fetchInterlocuteur', this.assureur_id)
                        this.messageToast('La mise à jour des interlocuteurs a été effectuée avec succès.', 'Interlocuteur', 'success', 'BellIcon')
                    }).catch(err => {
                          this.messageToast('La mise à jour des interlocuteurs a échoué.', 'Interlocuteur', 'error', 'BellIcon');
                    })
                }
            })
        },
        hideModal() {
             this.$nextTick(() => {
                this.$refs['modal-interlocuteur'].toggle('#toggle-btn')
            })
        },
        showModal(data = null) {
            console.log(data)
            if (data != null) {
                console.log(data.fonction)
                this.interlocuteur.id = data.id
                this.interlocuteur.fonction = data.fonction
                this.interlocuteur.nom = data.nom
                this.interlocuteur.prenom = data.prenom
                this.interlocuteur.phone = data.phone
                this.interlocuteur.email = data.email
                this.interlocuteur.assureur_id = this.assureur_id
                console.log(this.interlocuteur)
            }
            else {
                this.interlocuteur.id = null
                this.interlocuteur.fonction = null
                this.interlocuteur.nom = null
                this.interlocuteur.prenom = null
                this.interlocuteur.phone = null
                this.interlocuteur.email = null
                this.interlocuteur.assureur_id = this.assureur_id

            }
            
        },
        resetModal() {
            this.interlocuteur.id = null
            this.interlocuteur.fonction = null
            this.interlocuteur.nom = null
            this.interlocuteur.prenom = null
            this.interlocuteur.phone = null
            this.interlocuteur.email = null
        },
    }
}
</script>
<style>
.title-custom-wizard{
    color: #4d25bc !important ;
    margin-bottom: 5px;
}

.box-icon-text{
    margin-top: -4px;
    margin-right: 5px;
}
.edit-img {
    position: absolute;
    bottom: -5px;
    right: -15px;
    z-index: 6;
}
</style>